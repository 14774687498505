<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <purchases-invoice :purchasesInvoice="purchasesPayment.invoice" />
        </div>

        <div v-if="purchasesPayment.status !== PAYMENT_STATUS_CANCELED" class="col-6 text-right">
          <base-button
            type="button"
            size="sm"
            @click="validatePurchasesPayment(purchasesPayment)"
            class="elite-button add"
            v-if="purchasesPayment.status != PAYMENT_STATUS_COMPLETED"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.VALIDATE") }}
            </span>
          </base-button>
          <base-button
            type="button"
            size="sm"
            @click="cancelPurchasesPayment(purchasesPayment)"
            class="elite-button add"
          >
            <span class="btn-inner--icon">
              <i class="far fa-times"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.CANCEL") }}
            </span>
          </base-button>
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              purchasesPayment.status == PAYMENT_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_PURCHASES_PAYMENTS)
            "
            @click="deletePurchasesPayment"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              purchasesPayment.status == PAYMENT_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_PURCHASES_PAYMENTS)
            "
            @click="editPurchasesPayment"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.DATE") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(purchasesPayment.date) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
        >
          <dt>{{ $t("COMMON.INVOICE") }}</dt>
          <dd>
            <object-link :object="purchasesPayment.invoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATION") }}</dt>
          <dd>
            <location :purchasesInvoice="purchasesPayment.invoice.id" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="purchasesPayment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <purchasesPayment-status-badge
              :purchasesPayment="purchasesPayment"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(purchasesPayment.amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PURCHASES_PAYMENTS.SOURCE") }}</dt>
          <dd>
            {{ $t(`PAYMENTS.${purchasesPayment.source}`) }}
          </dd>
        </dl>

        <dl class="row" v-if="purchasesPayment.note">
          <dt class="col-sm-4">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="purchasesPayment.note"></div>
          </dd>
        </dl>

        <dl class="row" v-if="purchasesPayment.excerpt">
          <dt>
            {{ $t("COMMON.EXCERPT") }}
          </dt>
          <dd>
            <div v-html="purchasesPayment.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="purchasesPayment.transaction_id">
          <dt>
            {{ $t("PURCHASES_PAYMENTS.TRANSACTION_ID") }}
          </dt>
          <dd>
            {{ purchasesPayment.transaction_id }}
          </dd>
        </dl>

        <dl class="row" v-if="purchasesPayment.transaction_data">
          <dt>
            {{ $t("PURCHASES_PAYMENTS.TRANSACTION_DATA") }}
          </dt>
          <dd>
            {{ purchasesPayment.transaction_data }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.VEHICLE_CODE") }}</dt>
          <dd>
            <Vehicle :vehicle="purchasesPayment.transaction.vehicle" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="purchasesPayment.created_at">
            {{ $timeZoneDateFormat(purchasesPayment.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="purchasesPayment.updated_at">
            {{ $timeZoneDateFormat(purchasesPayment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div v-if="purchasesPayment.source === PAYMENT_SOURCE_DPA && purchasesPayment.paymentMethod" class="payment-method">
      <div class="title-header">
        <h3>{{ $t("PAYMENT_METHODS.PAYMENT_METHOD") }}</h3>
      </div>
      <global-payment-method-card
        :payment-method="purchasesPayment.paymentMethod"
      />
    </div>
  </div>
</template>

<script>
import GlobalPaymentMethodCard from "@/views/Pages/AdminModule/PaymentMethodManagement/components/GlobalPaymentMethodCard.vue";
import PurchasesPaymentStatusBadge from "./PurchasesPaymentStatusBadge.vue";
import {
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_SOURCE_DPA,
  PAYMENT_STATUS_CANCELED
} from "@/constants/payments";
import swal from "sweetalert2";
import Location from "@/components/Location";
import PurchasesInvoice from "@/components/PurchasesInvoice";
import Vehicle from "@/components/Vehicle";

export default {
  name: "purchasesPayment-view-global",

  components: {
    PurchasesPaymentStatusBadge,
    Location,
    PurchasesInvoice,
    Vehicle,
    GlobalPaymentMethodCard,
  },

  props: ["purchasesPayment"],

  data() {
    return {
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
      PAYMENT_STATUS_PENDING: PAYMENT_STATUS_PENDING,
      PAYMENT_STATUS_COMPLETED: PAYMENT_STATUS_COMPLETED,
      PAYMENT_SOURCE_DPA: PAYMENT_SOURCE_DPA,
      PAYMENT_STATUS_CANCELED: PAYMENT_STATUS_CANCELED,
    };
  },

  computed: {},

  created() {},

  methods: {
    validatePurchasesPayment() {
      this.$emit("onValidatePurchasesPayment", this.purchasesPayment);
    },
    cancelPurchasesPayment() {
      this.$emit("onCancelPurchasesPayment", this.purchasesPayment);
    },
    editPurchasesPayment() {
      this.$emit("onEditPurchasesPayment", this.purchasesPayment);
    },
    deletePurchasesPayment() {
      this.$emit("onDeletePurchasesPayment", this.purchasesPayment);
    },
  },

  mounted() {},

  watch: {
    purchasesPayment(purchasesPayment) {},
  },
};
</script>
