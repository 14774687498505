import {
  PAYMENT_SOURCE_MANUAL,
  PAYMENT_STATUS_COMPLETED,
} from "@/constants/payments";

export default {
  type: "purchases-payments",
  relationshipNames: ["invoice", "organization"],
  invoice: {
    type: "purchases-invoices",
    id: null,
  },
  amount: 0,
  status: PAYMENT_STATUS_COMPLETED,
  source: PAYMENT_SOURCE_MANUAL,
  note:null,
};
